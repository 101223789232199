import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonSpinner } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from "react-toastify";
import './ChangePassword.scss';
import { logout } from '../../store';
import { AuthResponse } from '../../models/api/AuthResponse';
import { IonLabel } from '@ionic/react';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { updateUserPassword } from '../../api-services/backend/user.service';
import DialogActions from '@material-ui/core/DialogActions';
import API_V2 from '../../api-services/svls-api';

type ChangePwdProps = {
  logout: Function;
  showTermsCondi?: boolean;
  closeHandler: () => void;
  backHandler: () => void;
};

type ChangePasswordRequest = {
  oldPassword: any;
  newPassword: string;
};

const ChangePwdForm: React.FC<ChangePwdProps> = (props) => {
  const { logout, closeHandler, showTermsCondi } = props;
  const [progress, setProgress] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(null);
  const [successMsg, setSuccessMsg] = useState<string>(null);

  const formik = useFormik({
    initialValues: {
      oldPwd: '',
      newPwd: '',
      confNewPwd: '',
      acceptTerms: false,
    },
    validationSchema: Yup.object({
      oldPwd: Yup.string().required('Required'),
      newPwd: Yup.string()
        .required('Required')
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,}$/,
          'Please choose a strong password. For more details hover on help icon(?).'
        ),
      confNewPwd: Yup.string().required('Required'),
      acceptTerms: showTermsCondi
        ? Yup.bool().test(
            'pointsType',
            'Please accept terms and conditions',
            () => {
              return formik.values.acceptTerms;
            }
          )
        : Yup.string().optional(),
    }),
    onSubmit: (values) => {
      setErrorMsg(null);
      setSuccessMsg(null);
      const data: ChangePasswordRequest = {
        oldPassword: values.oldPwd.trim(),
        newPassword: values.newPwd.trim(),
      };

      if (values.newPwd === values.confNewPwd) {
        if (values.newPwd === values.oldPwd) {
          setErrorMsg(
            'New password should not be same as username/old password'
          );
        } else {
          updateNewPassword(data);
        }
      } else {
        setErrorMsg('Passwords mismatch');
      }
    },
  });

  const updateNewPassword = async (data: ChangePasswordRequest) => {
    try {
      setErrorMsg('')
      setSuccessMsg('');
      setProgress(true);

      const userName = sessionStorage.getItem('username');
      const response: AuthResponse = await API_V2.put(`/account/v2/users/${userName}/password:change`, data, {
        headers: {
          Authorization: sessionStorage.getItem('jwt_token'),
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 204) {
        setSuccessMsg('Password changed successfully.');

        logout();
        setTimeout(() => {
          closeHandler();
        }, 2000);
      }
    } catch (err) {
      setErrorMsg(err?.response?.data?.message);
    } finally {
      setProgress(false);
    }
  };
  return (
    <div className="password-ctn">
      {/* <IonRow className="title-row">
        <div className = "title">Change Password</div>
      </IonRow> */}
      <form
        onSubmit={formik.handleSubmit}
        className="change-pwd-form-ctn"
        autoComplete="off"
      >
        <div className="form-input">
          <IonLabel className="input-label">Enter old password</IonLabel>
          <TextField
            className="login-input-field user-name"
            type="password"
            name="oldPwd"
            variant="outlined"
            error={formik.touched.oldPwd && formik.errors.oldPwd ? true : false}
            helperText={
              formik.touched.oldPwd && formik.errors.oldPwd
                ? formik.errors.oldPwd
                : null
            }
            {...formik.getFieldProps('oldPwd')}
          />
        </div>

        <div className="form-input">
          <IonLabel className="input-label">
            New Password
            <Tooltip
              className="input-tooltip"
              title="Password must contains at least 8 characters and at most 20 characters (It contains at least One Uppercase, One Lowercase and One Number)"
            >
              <HelpOutlineIcon color="primary" />
            </Tooltip>
          </IonLabel>
          <FormControl
            className="login-input-field pwd-field"
            variant="outlined"
            error={formik.touched.newPwd && formik.errors.newPwd ? true : false}
          >
            <OutlinedInput
              id="standard-adornment-password"
              type="password"
              name="newPwd"
              {...formik.getFieldProps('newPwd')}
            />
            {formik.touched.newPwd && formik.errors.newPwd ? (
              <FormHelperText error id="my-helper-text">
                {formik.errors.newPwd}
              </FormHelperText>
            ) : null}
          </FormControl>
        </div>

        <div className="form-input">
          <IonLabel className="input-label">Confirm New Password</IonLabel>
          <FormControl
            className="login-input-field pwd-field"
            variant="outlined"
            error={
              formik.touched.confNewPwd && formik.errors.confNewPwd ? true : false
            }
          >
            <OutlinedInput
              id="standard-adornment-password"
              type="password"
              name="confNewPwd"
              {...formik.getFieldProps('confNewPwd')}
            />
            {formik.touched.confNewPwd && formik.errors.confNewPwd ? (
              <FormHelperText error id="my-helper-text">
                {formik.errors.confNewPwd}
              </FormHelperText>
            ) : null}
          </FormControl>
        </div>

        {showTermsCondi ? (
          <div className="accept-terms-input">
            <FormControlLabel
              className="accept-terms-field"
              control={
                <Checkbox
                  checked={formik.values.acceptTerms}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  className="accept-terms-checkbox"
                  name="acceptTerms"
                  color="default"
                />
              }
              label="I understand & accept the terms and conditions"
              labelPlacement="end"
            />
            {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
              <FormHelperText id="my-helper-text" className="my-helper-text">
                {formik.errors.acceptTerms}
              </FormHelperText>
            ) : null}
          </div>
        ) : null}

        {errorMsg !== '' ? <span className="error-msg">{errorMsg}</span> : null}
        {successMsg !== '' ? (
          <span className="success-msg">{successMsg}</span>
        ) : null}

        <DialogActions>
          <Button
            className="submit-form-btn"
            endIcon={progress ? <IonSpinner name="lines-small" /> : ''}
            type="submit"
            variant="contained"
          >
            Change
          </Button>
        </DialogActions>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(ChangePwdForm);
