import kabaddi from '../assets/images/sportsbook/icons/kabaddi.png';
import volleyball from '../assets/images/sportsbook/icons/volleyball.png';
import basketball from '../assets/images/sportsbook/icons/basketball.png';
import motorrace from '../assets/images/sportsbook/icons/motorrace.png';
import greyhound from '../assets/images/sportsbook/icons/greyhoundracing.svg';
import horseracing from '../assets/images/sportsbook/icons/hourseracing.svg';
import cricket from '../assets/images/sportsbook/icons/4.png';
import football from '../assets/images/sportsbook/icons/1.png';
import tennish from '../assets/images/sportsbook/icons/2.png';
import draft from '../assets/images/sportsbook/icons/5.png';
import hockey from '../assets/images/sportsbook/icons/6.png';
import tabletennis from '../assets/images/sportsbook/icons/7.png';
import boxing from '../assets/images/sportsbook/icons/8.png';
export const EXCHANGE_EVENT_TYPES = [
  { id: '4', name: 'Cricket', slug: 'cricket', img: cricket },
  { id: '2', name: 'Tennis', slug: 'tennis', img: tennish },
  { id: '1', name: 'Football', slug: 'football', img: football },
  { id: '7', name: 'Horse Racing', slug: 'horseracing', img: horseracing },
  // { id: '4339', name: 'greyhound', slug: 'greyhoundracing', img: greyhound },
  // { id: '7511', name: 'Baseball', slug: 'baseball', img: volleyball },
  // { id: '7522', name: 'Basketball', slug: 'basketball', img: basketball },
];

export const EXCHANGE_EVENT_TYPES_MAP = new Map([
  [
    'Football',
    {
      id: '1',
      name: 'Football',
      slug: 'football',
      url: '/exchange_sports/football',
      img: football,
      priority: 0,
      disable: false,
    },
  ],
  [
    'Tennis',
    {
      id: '2',
      name: 'Tennis',
      slug: 'tennis',
      url: '/exchange_sports/tennis',
      img: tennish,
      priority: 1,
      disable: false,
    },
  ],
  [
    'Cricket',
    {
      id: '4',
      name: 'Cricket',
      slug: 'cricket',
      url: '/exchange_sports/cricket',
      img: cricket,
      priority: 2,
      disable: false,
    },
  ],
  [
    'Horse Racing',
    {
      id: '7',
      name: 'Horse Racing',
      slug: 'horseracing',
      url: '/exchange_sports/horseracing',
      img: horseracing,
      priority: 3,
      disable: false,
    },
  ],
]);

export const EXCH_SPORTS_MAP = {
  tennis: '2',
  football: '1',
  soccer: '1',
  cricket: '4',
  baseball: '7511',
  basketball: '7522',
  greyhoundracing: '4339',
  horseracing: '7',
};
export const EVENT_TYPES = [
  { id: '1', name: 'Football', slug: 'football', img: football },
  { id: '2', name: 'Tennis', slug: 'tennis', img: tennish },
  { id: '4', name: 'Cricket', slug: 'cricket', img: cricket },
  { id: '7', name: 'Horse Racing', slug: 'horseracing', img: horseracing },
  // { id: '4339', name: 'Greyhound', slug: 'greyhound', img: greyhound },
  // { id: '7522', name: 'Basketball', slug: 'basketball', img: basketball },
  // { id: '7511', name: 'Baseball', slug: 'baseball', img: volleyball },

  // { id: '103', name: 'Table Tennis', slug: 'tabletennis', img: tabletennis },
  // { id: '104', name: 'Darts', slug: 'darts', img: draft },
  // { id: '105', name: 'Badminton', slug: 'badminton', img: basketball },
  // { id: '106', name: 'kabaddi', slug: 'kabaddi', img: kabaddi },
  // { id: '100', name: 'Ice Hockey', slug: 'icehockey', img: hockey },
  // { id: '107', name: 'Boxing', slug: 'boxing', img: boxing },

  // {
  //   id: '108',
  //   name: 'Mixed Martial Arts',
  //   slug: 'horse racing',
  //   img: horseracing,
  // },
  // { id: '109', name: 'Greyhound', slug: 'greyhound raching', img: greyhound },
  // { id: '110', name: 'Motor Sport', slug: 'motor race', img: motorrace },
];
