import React, { useState } from 'react';
import './Login.scss';
import LoginCard from '../../components/LoginForm/LoginCard';
import Card from '@material-ui/core/Card';
import webtitleImg from '../../assets/images/brand/brand.svg';
import { IonImg } from '@ionic/react';
const Login: React.FC = () => {
  const [isEntryLoader, setIsEntryLoader] = useState<boolean>(true);

  setInterval(() => {
    setIsEntryLoader(false);
  }, 3000);
  return (
    <div id="bg">
      <div className="center-content">
        {isEntryLoader ? (
          <>
            <div className="logo_loading">
              {/* <IonImg src={webtitleImg} className="logo" /> */}
              {/* <div className="logo-text">SilverExch</div> */}
              <i className="fa fa-spinner fa-spin fa-fw" aria-hidden="true"></i>
            </div>
          </>
        ) : (
          <>
            <IonImg src={webtitleImg} className="logo" />
            {/* <div className="logo-text">SilverExch</div> */}
            <Card className="card">
              <LoginCard />
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
